<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-bank"
                                        style="transform: scale(1.7);"></i> Banks</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to manage banks. </label>
                        <div v-if="addingBank">
                            <button @click="addingBank = false" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                            <h4 class="header-title">Please provide the following informations</h4>
                            <p class="sub-header">
                                All fields are required
                                <!-- <code class="highlighter-rouge">.row</code> for
                            <code class="highlighter-rouge">.form-row</code>, a variation of our standard grid row that
                            overrides the default column gutters for tighter and more compact layouts. -->
                            </p>

                            <form @submit.prevent="preventsubmit">
                                <div class="form-row">
                                    <b-form-group label="Bank name" label-for="bankname">
                                        <b-form-input type="text" id="bankname" v-model="bank.Name"
                                           ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" label="Email" label-for="input-sm">
                                        <b-form-input type="email" id="input-sm" v-model="bank.Email"
                                           ></b-form-input>
                                    </b-form-group>
                                </div>
                                <b-form-group label="Phone number" label-for="phoneNumber">
                                    <b-form-input type="text" id="phoneNumber" v-model="bank['Phone number']"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group label="Address" label-for="inputAddress">
                                    <b-form-input type="text" id="inputAddress" v-model="bank.Address"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group label="Website" label-for="Website">
                                    <b-form-input type="text" id="Website" v-model="bank.Website"
                                        ></b-form-input>
                                </b-form-group>
                                <label class="d-block mt-4">Logo link</label>
                                <b-tabs justified nav-class="nav-tabs nav-bordered">
                                    <b-tab title="Logo link" @click="inputchoice = 1" active>
                                        <b-form-input type="text" id="logoUrl" v-model="logoUrl"
                                            ></b-form-input>
                                    </b-tab>
                                    <b-tab title="Upload logo" @click="inputchoice = 2">
                                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
                                            :options="dropzoneOptions" @vdropzone-success="handleDropzoneSuccess">
                                            <div class="dz-message needsclick">
                                                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                        </vue-dropzone>
                                    </b-tab>
                                </b-tabs>

                                <label class="d-block mt-4">BANK CODES</label>
                                <b-tabs justified nav-class="nav-tabs nav-bordered">
                                    <b-tab title="INPUT BANK CODES" @click="inputchoice2 = 1" active>
                                        <table class="table">
                                    <tr v-for="i in click ">
                                        <td>
                                            <label v-if="i==1" class="d-block">CASH FLOW</label>
                                            <b-form-input type="text" id="bankCode" v-model="CodesTable[i-1]['CASH FLOW']"></b-form-input>
                                        </td>
                                        <td>
                                            <label v-if="i==1" class="d-block">CIB 1</label>
                                            <b-form-input type="text" id="bankCode" v-model="CodesTable[i-1]['CIB1']"></b-form-input>
                                        </td>
                                        <td>
                                            <label v-if="i==1" class="d-block">CIB 2</label>
                                            <b-form-input type="text" id="bankCode" v-model="CodesTable[i-1]['CIB2']"></b-form-input>
                                        </td>

                                        <td>
                                            <button v-if="i==click"  type="button" @click="addBankCode(i)"
                                                class="btn btn-primary ml-2"><i class="mdi mdi-plus"></i></button>
                                        </td>
                                    </tr>
                                </table>
                                    </b-tab>
                                    <b-tab title="UPLOAD BANK CODES" @click="inputchoice2 = 2">
                                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
                                            :options="dropzoneOptions2" @vdropzone-success="handleDropzoneSuccess2">
                                            <div class="dz-message needsclick">
                                                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                        </vue-dropzone>
                                        
                                    </b-tab>
                                    <div v-if="data.length" class="table-responsive mt-5">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>                                            
                                                <th v-for="label in dataLabels">{{ label }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(entry, index) in data" :key="`tabledata_${index}`"
                                                @click="toggleCheckbox(index)">                                            
                                                <td v-for="(cell, indexx) in entry" :key="`celldata_${indexx}_${index}`"> {{
                                                    cell }} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                                </b-tabs>                             


                                

                                <div class="mt-5 mb-5 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">New bank</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingBank = true"><i
                                            class="mdi mdi-bank-plus"></i></button>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="Banks" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered"></b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import vue2Dropzone from "vue2-dropzone";
import { read, utils, write } from 'xlsx';

export default {
    data() {
        return {
            click:1,
            data: [],
            dataLabels: [],
            addingBank: false,
            dropZoneFile: null,
            logoUrl: "",
            inputchoice: -1,
            inputchoice2: -1,
            value: "",
            dropzoneOptions: {
                url: "https://httpbin.org/post",
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                maxFiles: 1,
                acceptedFiles: '.png, .jpg, .jpeg, .svg'
            },
            dropzoneOptions2: {
                url: "https://httpbin.org/post",
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                maxFiles: 1,
                acceptedFiles: '.xls, .xlsx, .xlsm, .xlsb'
            },
            
            bank: {
                Societe: localStorage.getItem("societe").slice(1, -1),
                Name: "",
                Address: "",
                "Phone number": "",
                Website: "",
                Email: "",
                Logo: "",
                Codes: [{
                    "CASH FLOW":"",
                    "CIB1": "",
                    "CIB2": "",
                }],
            },
            Banks: [],
            CodesTable:[ {
                "CASH FLOW": "",
                "CIB1": "",
                "CIB2": "",
            }],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "Logo",
                    sortable: false,
                },
                {
                    key: "Name",
                    sortable: true,
                },
                {
                    key: "Phone number",
                    sortable: true,
                },
                {
                    key: "Email",
                    sortable: true,
                },
                {
                    key: "Address",
                    sortable: true,
                },
                {
                    key: "Website",
                    sortable: true,
                },
            ],
        }
    },
    components: {
        Layout,
        vueDropzone: vue2Dropzone,
    },
    async mounted() {
        await this.getBanks()
        this.replaceTdWithImage()
    },
    computed: {
        rows() {
            return this.Banks.length;
        },
    },
    methods: {
        addBankCode(i) {
            this.CodesTable[i]= {
                "CASH FLOW": "null",
                "CIB1": "null",
                "CIB2": "null",
            }
            console.log("CodesTable "+this.click,this.CodesTable)
            if(this.CodesTable[i-1]["CASH FLOW"] != "" && this.CodesTable[i-1]["CIB1"] != "" && this.CodesTable[i-1]["CIB2"] != ""){
                this.click++;
            }
            
        },
        replaceTdWithImage() {
            const tdElements = document.querySelectorAll('td[aria-colindex="1"]')
            tdElements.forEach(tdElement => {
                const imageName = tdElement.textContent.trim()
                const imgElement = document.createElement('img')
                imgElement.src = `https://finex.4help.tn/uploads/${imageName}`
                imgElement.style.height = window.getComputedStyle(tdElement).height
                imgElement.style.transform = "scale(0.5)"
                imgElement.style.objectFit = 'stretch'

                tdElement.parentNode.replaceChild(imgElement, tdElement)
            })
        },
        async getBanks() {
            this.Banks = await axios.get(`https://finex.4help.tn/api/v1/getbanks/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
            console.log(this.Banks)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async handleSumbit() {
            let logodata = null
            if (this.inputchoice == 2) {
                logodata = this.dropZoneFile
            } else {
                
                this.bank.Logo = await this.getImageFile(this.logoUrl)
                console.log(this.bank.Logo)
            }
            this.bank.Codes = this.CodesTable;
            console.log("bankkkkk", this.bank)
            const formData = new FormData()
            formData.append('image', logodata)
            formData.append('bankInfo', JSON.stringify(this.bank))
            try {
                await axios.post('https://finex.4help.tn/api/v1/addbank', formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data", // Add this header for file upload
                        },
                    }).then(async (res) => {
                        console.log(res)
                        if (res.status === 200) {
                            swal({
                                title: 'Success',
                                text: `Successfully added ${this.bank.Name} bank!`,
                                icon: 'success',
                            });
                            this.addingBank = false
                            await this.getBanks()
                            this.currentPage = 1
                            await this.replaceTdWithImage()
                            this.logoUrl = ""
                            this.bank = {
                                Name: "",
                                Address: "",
                                "Phone number": "",
                                Website: "",
                                Email: "",
                                Logo: "",
                                
                            }

                        }
                    })
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    swal({
                        title: 'Conflict',
                        text: `Bank already exists, please try again.`,
                        icon: 'error',
                    });
                }
                else {
                    swal({
                        title: 'Error',
                        text: `Server error.`,
                        icon: 'error',
                    });
                }
            }
            this.showPopup = true;

            this.isPopupVisible = true;
        },
        handleDropzoneSuccess(file, response) {
            this.bank.Logo = file.upload.filename
            this.dropZoneFile = file
            
        },
        handleDropzoneSuccess2(file, response) {
            this.handleFileUpload(file)            
            this.dropZoneFile = file
            
        },
        async handleFileUpload(selectedFile) {        
                    if (selectedFile) {
                        this.fileUploaded = true
                        const readFileAsync = (selectedFile) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onload = (e) => resolve(e.target.result)
                                reader.onerror = (e) => reject(e)
                                reader.readAsBinaryString(selectedFile)
                            });
                        };
                        try {
                            const data = await readFileAsync(selectedFile)
                            const workbook = read(data, { type: 'binary' })
                            const sheetName = workbook.SheetNames[0]
                            const sheet = workbook.Sheets[sheetName]
                            this.data = utils.sheet_to_json(sheet, { header: 1 })
                            //this.dataLabels = this.data[0]
                            this.dataLabels = this.data[0].map(column => column.trim())
                            const dataModel = ['CASH FLOW', 'CIB1', 'CIB2']
                            if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
                                swal({
                                    title: 'Error',
                                    text: `Fields missmatch, please use our template.`,
                                    icon: 'error',
                                })
                                this.data = []
                                return
                            }
                            this.data.shift()
                            
                            
                            this.data.forEach(row => {                            
                                this.CodesTable.push({
                                    "CASH FLOW": row[0],
                                    "CIB1": row[1].toString(),
                                    "CIB2": row[2].toString(),
                                })                            
                            });
                            this.CodesTable.shift();
                            console.log(this.CodesTable)
                            
                        } catch (error) {
                            console.error(error)
                            // Handle errors here
                        }
                    }
                        

        },        
        async getImageFile(imageUrl) {
            return new Promise((resolve, reject) => {
                axios.post('https://finex.4help.tn/api/v1/downloadpic', { url: imageUrl })
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        preventsubmit(e) {
            e.preventDefault()
        }
    }
}

</script>

<style scoped>
.card {
    min-height: 100%;
    margin-bottom: 0px !important;
}

h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f2f2f2 !important;
    font-weight: bold;
    color: #333;
}


tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

tr:hover {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    transition: background-color 0.3s;
}</style>